/*global _ */
import axios from 'axios'
import {loadProgressBar} from 'axios-progress-bar'

export const getHeaders = () => {
  //request.headers['X-Socket-Id'] = Echo.socketId()
  const headers = {
    'X-Requested-With': 'XMLHttpRequest'
  }

  const csrfTokenElement = document.head.querySelector('meta[name="csrf-token"]');
  const csrf = csrfTokenElement && csrfTokenElement.content || ''
  if (csrf) {
    headers['X-CSRF-TOKEN'] = csrf
  }

  let authToken = _.get(window, 'addiesaas.store.getters.authToken')
  if (!authToken) {
    const authTokenElement = document.head.querySelector('meta[name="auth-token"]');
    authToken = authTokenElement && authTokenElement.content || '';
  }
  if (authToken) {
    headers['X-Authorization'] = 'Bearer ' + authToken
  }

  return headers
}

export const setHeaders = (headers, customAxios) => {
  customAxios = customAxios || axios
  customAxios.interceptors.request.use(request => {
    const freshHeaders = headers || getHeaders()
    _.forOwn(freshHeaders, (value, key) => {
      request.headers[key] = value
    })
    return request
  })
}

export default (router, store, customAxios) => {
  loadProgressBar({showSpinner: false});
  customAxios = customAxios || axios
  setHeaders(null, customAxios)
  return customAxios
}
