<template lang="pug">
  div(:class="this.$route.name")
    .b-header
      img.b-header__logo(:src="`${config.baseUri}/images/client/saas-onboarding/logo.sm.svg`")
      .b-header__links
        span.b-header__links-message {{ header.message }}
        router-link.btn.btn-almost-sm.btn-secondary(:to="{ name: header.route }") {{ header.button }}

    slot

</template>

<script>

const HEADER_TYPES = {
  signUp: {
    message: 'Already have an account?',
    route: 'onboarding-sign-in',
    button: 'Sign in',
  },
  signIn: {
    message: 'Don’t have an account?',
    route: 'onboarding-social',
    button: 'Get started',
  },
}

export default {
  name: 'OnboardingLayout',

  data: () => ({
    config: window.addiesaas.config,
  }),

  computed: {
    header() {
      return HEADER_TYPES[this.$route.meta.signIn ? 'signIn' : 'signUp']
    },
  },
}
</script>
