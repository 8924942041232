import config from '~/vuestrap/config'
const { Vue } = config

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import { InlineSvgPlugin } from 'vue-inline-svg'
Vue.use(InlineSvgPlugin)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

import 'main/admin/lib/adminstrap'
import 'utils/saascommon/admin/components'
import { router } from 'utils/saascommon/admin/libs'
import routes from './routes'
router(routes)

Vue.mixin({
  data: () => ({
    config: window.addiesaas.config,
  }),
})

import App from './views/App'
Vue.component(App.name, App)
