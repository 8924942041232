<template lang="pug">
  .not-found-layout
    .b-header
      img.b-header__logo(:src="`${config.baseUri}/images/client/saas-onboarding/logo.sm.svg`")
    .b-central
      .b-central__content.b-central__content--center
        img.b-central__image(:src="`${config.baseUri}/images/client/saas-onboarding/404.svg`")
        .b-central__title
          .d-block.d-sm-inline.pl-1.pr-1 Ooops! Sorry,
          .d-block.d-sm-inline.pl-1.pr-1 we can't find that page.
        .b-central__subtitle
          .d-block.d-sm-inline.pl-1.pr-1 Either something went wrong or the
          .d-block.d-sm-inline.pl-1.pr-1 page doesn't exist anymore.
        .b-central__footer
          router-link.btn.btn-almost-sm.btn-primary(:to="{ name: 'onboarding-sign-in' }") Go Back
      img.b-central__corner.b-central__corner--desktop(:src="`${config.baseUri}/images/client/saas-onboarding/logo.bg.svg`")
      img.b-central__corner.b-central__corner--mobile(:src="`${config.baseUri}/images/client/saas-onboarding/logo.bg.mobile.svg`")

</template>

<script>
export default {
  name: 'NotFoundLayout',

  data: () => ({
    config: window.addiesaas.config,
  }),
}
</script>
