<template lang="pug">
  component(:is="layout")
    slot
</template>

<script>
import AuthLayout from './AuthLayout'
import NotFoundLayout from './NotFoundLayout'

const DEFAULT_LAYOUT = AuthLayout
const NOT_FOUND_LAYOUT = NotFoundLayout

export default {
  name: 'app-layout',

  computed: {
    layout() {
      console.log()
      if (this.$route.matched.length > 0) {
        return this.$route.meta.layout || DEFAULT_LAYOUT
      } else {
        return NOT_FOUND_LAYOUT
      }
    }
  }
}
</script>
