import api from "!../../../../../../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-39.use[1]!../../../../../../../../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-39.use[2]!../../../../../../../../../../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-39.use[3]!./style.scss?vue&type=style&index=0&prod&lang=scss&external";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};