<template lang="pug">
  app-layout
    router-view
</template>

<script>
import AppLayout from '../layouts/AppLayout'

export default {
  name: 'saas-onboarding',
  components: { AppLayout },
}
</script>
